// import axios from 'axios'
import { PrivateApiCall, PublicApiCall, MessagesPrivateApiCall } from "./interceptors";

const errorCatch = (error) => {
  console.log("ERROR API CALL", error, error?.response);
  if (error.response) {
    if (error.reponse?.data) {
      return error.response?.data;
    }
    return error.response;
  } else {
    return error;
  }
};

/** ******************************************************************************************* */
/** ************************************* ORGANIZATION ***************************************** */
/** ******************************************************************************************* */
//get modules
async function getModules() {
  return await PublicApiCall.get(`/organisation/modules`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************* LANGUAGE ******************************************** */
/** ******************************************************************************************* */
//get translation
async function getTranslation() {
  return await PublicApiCall.get(`/language/?platform=resto-app`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//get languages
async function getLanguages() {
  return await PublicApiCall.get('/language/app')
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************* DESIGN ***************************************** */
/** ******************************************************************************************* */
//get all designs
async function getDesigns() {
  return await PublicApiCall.get(`/app/restaurant/all-designs`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** *********************************** AFFILIATE LINKS *************************************** */
/** ******************************************************************************************* */
async function sendAffiliateLinkReference(reference) {
  return await PublicApiCall.put(`/app/affiliate-link/${reference}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************* RESTAURANTS ***************************************** */
/** ******************************************************************************************* */
//get all restaurants with no auth required
async function getAllRestaurants(key) {
  return await PublicApiCall.get(`/app/restaurant/all?key=${key}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ********************************* RESTO/BRANCH MODULES ************************************ */
/** ******************************************************************************************* */
//get modules
async function getRestoBranchModules(ruuid, buuid) {
  return await PublicApiCall.get(`/app/modules/${ruuid}${buuid ? `/${buuid}` : ""}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************  NOTIFICATIONS  ************************************** */
/** ******************************************************************************************* */

async function registerDevice(token) {
  return await PrivateApiCall.post(`/app/notifications`, { token: token })
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************ POPULAR ITEMS **************************************** */
/** ******************************************************************************************* */
async function getPopularItems(restaurantId) {
  return await PublicApiCall.get(`/app/menu/${restaurantId}/popular-items/`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** **************************************** MENUS ******************************************** */
/** ******************************************************************************************* */

// GET MENUS
async function getMenus(restaurantId) {
  return await PrivateApiCall.get(`/app/menu/${restaurantId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

// GET MENU
async function getAllProducts(isUserLoggedIn, restaurantId, menuId) {
  if (isUserLoggedIn) {
    return await PrivateApiCall.get(`/app/menu/${restaurantId}/${menuId}`)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  }
  //else
  return await PublicApiCall.get(`/app/menu/${restaurantId}/${menuId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** DISCOUNTS ****************************************** */
/** ******************************************************************************************* */
async function getDiscounts(isUserLoggedIn, restaurantId) {
  if (isUserLoggedIn) {
    return await PrivateApiCall.get(`/app/discount/${restaurantId}`)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  }
  //else
  return await PublicApiCall.get(`/app/discount/${restaurantId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getDiscount(isUserLoggedIn, restaurantId, discountId) {
  if (isUserLoggedIn) {
    return await PrivateApiCall.get(
      `/app/discount/${restaurantId}/${discountId}`
    )
      .then((response) => response)
      .catch((error) => errorCatch(error));
  }
  //else
  return await PublicApiCall.get(`/app/discount/${restaurantId}/${discountId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** PROMOTIONS ***************************************** */
/** ******************************************************************************************* */
async function getPromotions(isUserLoggedIn, restaurantId) {
  if (isUserLoggedIn) {
    return await PrivateApiCall.get(`/app/promotion/${restaurantId}`)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  }
  //else
  return await PublicApiCall.get(`/app/promotion/${restaurantId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getPromotion(isUserLoggedIn, restaurantId, promotionId) {
  if (isUserLoggedIn) {
    return await PrivateApiCall.get(
      `/app/promotion/${restaurantId}/${promotionId}`
    )
      .then((response) => response)
      .catch((error) => errorCatch(error));
  }
  //else
  return await PublicApiCall.get(
    `/app/promotion/${restaurantId}/${promotionId}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** *************************************** MEDIA ********************************************* */
/** ******************************************************************************************* */
async function getMedia(isUserLoggedIn, restaurantId, page, limit) {
  if (isUserLoggedIn) {
    return await PrivateApiCall.get(
      `/app/media/${restaurantId}?page=${page}&limit=${limit}`
    )
      .then((response) => response)
      .catch((error) => errorCatch(error));
  }
  // else
  return await PublicApiCall.get(
    `/app/media/${restaurantId}?page=${page}&limit=${limit}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//add user media interaction
async function addMediaInteraction(restaurantId, mediaId, data) {
  return await PrivateApiCall.post(
    `/app/mediaInteraction/${restaurantId}/${mediaId}`,
    data
  );
}

/** *************************************** MESSAGES ********************************************* */
/** ******************************************************************************************* */
async function getMessages(restaurantId, branchId, page, limit) {
  return await MessagesPrivateApiCall.get(
    `/app/${restaurantId}/${branchId}?pageNumber=${page}&pageLimit=${limit}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//send message
async function sendMessage(restaurantId, branchId, messages) {
  return await MessagesPrivateApiCall.put(
    `/app/${restaurantId}/${branchId}`,
    messages
  );
}

//get unread messages
async function getUnreadMessages(restaurantId) {
  return await MessagesPrivateApiCall.get(
    `/app/${restaurantId}/global/restaurant`);
}

/** ******************************************************************************************* */
/** ************************************* LOCATION ***************************************** */
/** ******************************************************************************************* */
//get nearest branch
async function getNearestBranch(restaurantId, lng, lat, key) {
  return await PublicApiCall.get(
    `/app/location/${restaurantId}/nearest-branch?long=${lng}&lat=${lat}&key=${key}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getNearestDeliveryBranch(restaurantId, lng, lat, key) {
  return await PublicApiCall.get(
    `/app/location/${restaurantId}/nearest-branch-delivery?long=${lng}&lat=${lat}&key=${key}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ************************************ EVENTS **************************************** */
/** ******************************************************************************************* */
async function getEvents(restaurantId) {
  return await PublicApiCall.get(`/app/events/${restaurantId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ***************************************** ORDERS ****************************************** */
/** ******************************************************************************************* */
//get orders
async function getOrders(restaurantId) {
  return await PrivateApiCall.get(`/app/order/${restaurantId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//get order
async function getOrder(restaurantId, branchId, orderId) {
  return await PrivateApiCall.get(
    `/app/order/${restaurantId}/${branchId}/${orderId}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//calculate order
async function calculateOrder(restaurantId, branchId, data) {
  return await PrivateApiCall.post(
    `/app/order/${restaurantId}/${branchId}/calculate-order`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//add order
async function sendOrder(restaurantId, branchId, data) {
  return await PrivateApiCall.post(
    `/app/order/${restaurantId}/${branchId}/place-order`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//cancel order
async function cancelOrder(restaurantId, branchId, orderId) {
  return await PrivateApiCall.put(
    `/app/order/${restaurantId}/${branchId}/${orderId}/cancel-order`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//get order history
async function getOrderHistory(restaurantId, orderId) {
  return await PrivateApiCall.get(
    `/app/order/${restaurantId}/${orderId}/history`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ************************************ FEEDBACK **************************************** */
/** ******************************************************************************************* */
//get resto feedback template
async function getFeedbackTemplate(restaurantId) {
  return await PrivateApiCall.get(`/app/feedback/${restaurantId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//add logged in user feedback
async function addFeedback(restaurantId, data) {
  return await PrivateApiCall.post(`/app/feedback/${restaurantId}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//add qr user feedback
async function addFeedbackQr(restaurantId, data) {
  return await PublicApiCall.post(`/app/feedback/${restaurantId}/qr`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** AUTH ******************************************* */
/** ******************************************************************************************* */

//signup
async function signup(data) {
  return await PublicApiCall.post(`/app/auth/sign-up`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//login with phone
async function login(phone) {
  return await PublicApiCall.post(`/app/auth/sign-in-with-phone`, phone)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//verify account
async function verify(data) {
  return await PublicApiCall.post(`/app/auth/verify`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//get otp (temporal)
async function getOtp(phone) {
  return await PublicApiCall.get(`/app/auth/otp/${phone}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************* Image Upload **************************************** */
/** ******************************************************************************************* */

//upload image
async function uploadImage(data) {
  return await PrivateApiCall.post(`/app/upload/image`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ***************************************** User ******************************************** */
/** ******************************************************************************************* */

//get me
async function getMe() {
  return await PrivateApiCall.get(`/app/me`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//update profile
async function updateProfile(data) {
  return await PrivateApiCall.put(`/app/profile/updateProfile`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//update phone
async function updatePhone(data) {
  return await PrivateApiCall.put(`/app/profile/updatePhone`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//get otp after phone update (temporal)
async function getOtpAfterPhoneUpdate(phone) {
  return await PrivateApiCall.get(`/app/profile/otp/${phone}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//verify account after phone update
async function verifyAfterPhoneUpdate(data) {
  return await PrivateApiCall.post(`/app/profile/verify`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//get allergens
async function getAllergens(data) {
  return await PrivateApiCall.get(`/app/allergen`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************ User Addresses *************************************** */
/** ******************************************************************************************* */

//get addresses
async function getAddresses() {
  return await PrivateApiCall.get(`/app/user-address`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//add address
async function addAddress(data) {
  return await PrivateApiCall.post(`/app/user-address`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

//edit address
async function editAddress(addressId, data) {
  return await PrivateApiCall.put(`/app/user-address/${addressId}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** *************************************** LOYALTY ******************************************* */
/** ******************************************************************************************* */
async function getLoyalty(restaurantId) {
  return await PublicApiCall.get(`/app/menu/${restaurantId}/loyalty`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** FAVORTIES ****************************************** */
/** ******************************************************************************************* */
async function getFavorites(restaurantId) {
  return await PrivateApiCall.get(`/app/favorite/${restaurantId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addFavorite(restaurantId, data) {
  return await PrivateApiCall.post(`/app/favorite/${restaurantId}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteFavorite(restaurantId, favoriteId) {
  return await PrivateApiCall.delete(`/app/favorite/${restaurantId}/${favoriteId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************ GALLERY **************************************** */
/** ******************************************************************************************* */
async function getGallery(restaurantId) {
  return await PublicApiCall.get(`/app/gallery/${restaurantId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

export {
  // ORGANIZATION
  getModules,
  // TRANSLATION
  getTranslation,
  getLanguages,
  // DESIGNS
  getDesigns,
  // AFFILIATE LINKS
  sendAffiliateLinkReference,
  // RESTAURANTS
  getAllRestaurants,
  // RESTO/BRANCH MODULES
  getRestoBranchModules,
  // NOTIFICATIONS
  registerDevice,
  // POPULAR ITEMS
  getPopularItems,
  // MENUS
  getMenus,
  getAllProducts,
  // DISCOUNTS
  getDiscounts,
  getDiscount,
  // PROMOTIONS
  getPromotions,
  getPromotion,
  // MEDIA
  getMedia,
  addMediaInteraction,
  // MESSAGES
  getMessages,
  sendMessage,
  getUnreadMessages,
  // LOCATION
  getNearestBranch,
  getNearestDeliveryBranch,
  // EVENTS
  getEvents,
  // ORDERS
  getOrders,
  getOrder,
  calculateOrder,
  sendOrder,
  cancelOrder,
  getOrderHistory,
  // FEEDBACK,
  getFeedbackTemplate,
  addFeedback,
  addFeedbackQr,
  // GALLERY
  getGallery,
  // AUTH
  signup,
  login,
  verify,
  getOtp,
  // UPLOAD IMAGE
  uploadImage,
  // USER
  getMe,
  updateProfile,
  updatePhone,
  getOtpAfterPhoneUpdate,
  verifyAfterPhoneUpdate,
  getAllergens,
  // ADDRESSES
  getAddresses,
  addAddress,
  editAddress,
  // LOYALTY
  getLoyalty,
  //FAVORITES
  getFavorites,
  addFavorite,
  deleteFavorite
};
