import {
  getAllRestaurants,
  getNearestDeliveryBranch,
  getRestoBranchModules
} from "../../config/config";
import moment from "moment";
import { notify } from "../../utils/notify";
import { actions as GlobalActions } from "./GlobalActions";

import { SET_EXCLUDED_MODULES } from "./OrganizationActions";

export const GET_RESTAURANTS_REQUEST = "GET_RESTAURANTS_REQUEST";
export const GET_RESTAURANTS_SUCCESS = "GET_RESTAURANTS_SUCCESS";
export const GET_RESTAURANTS_ERROR = "GET_RESTAURANTS_ERROR";

export const GET_FEEDBACK_RESTAURANTS_REQUEST = "GET_FEEDBACK_RESTAURANTS_REQUEST";
export const GET_FEEDBACK_RESTAURANTS_SUCCESS = "GET_FEEDBACK_RESTAURANTS_SUCCESS";
export const GET_FEEDBACK_RESTAURANTS_ERROR = "GET_FEEDBACK_RESTAURANTS_ERROR";

export const SET_SELECTED_RESTAURANT = "SET_SELECTED_RESTAURANT";
export const SET_USER_SELECTED_BRANCH = "SET_USER_SELECTED_BRANCH";
export const SET_DELIVERY_SELECTED_BRANCH = "SET_DELIVERY_SELECTED_BRANCH";

export const GET_NEAREST_DELIVERY_BRANCH_REQUEST =
  "GET_NEAREST_DELIVERY_BRANCH_REQUEST";
export const GET_NEAREST_DELIVERY_BRANCH_SUCCESS =
  "GET_NEAREST_DELIVERY_BRANCH_SUCCESS";
export const GET_NEAREST_DELIVERY_BRANCH_ERROR =
  "GET_NEAREST_DELIVERY_BRANCH_ERROR";

export const RESET_NEAREST_DELIVERY_BRANCH = "RESET_NEAREST_DELIVERY_BRANCH";

export const SET_DINE_IN_SELECTED_RESTAURANT =
  "SET_DINE_IN_SELECTED_RESTAURANT";
export const SET_DINE_IN_SELECTED_BRANCH = "SET_DINE_IN_SELECTED_BRANCH";
export const SET_DINE_IN_SELECTED_TABLE = "SET_DINE_IN_SELECTED_TABLE";

export const SET_PAY_SELECTED_RESTAURANT = "SET_PAY_SELECTED_RESTAURANT";
export const SET_PAY_SELECTED_BRANCH = "SET_PAY_SELECTED_BRANCH";
export const SET_PAY_SELECTED_TABLE = "SET_PAY_SELECTED_TABLE";

export const SET_QR_SELECTED_RESTAURANT = "SET_QR_SELECTED_RESTAURANT";
export const SET_QR_SELECTED_BRANCH = "SET_QR_SELECTED_BRANCH";

export const GET_RESTAURANT_MODULES_REQUEST = "GET_RESTAURANT_MODULES_REQUEST";
export const GET_RESTAURANT_MODULES_SUCCESS = "GET_RESTAURANT_MODULES_SUCCESS";
export const GET_RESTAURANT_MODULES_ERROR = "GET_RESTAURANT_MODULES_ERROR";

export const SET_RESTAURANT_BRANCH_HAS_LOYALTY = "SET_RESTAURANT_BRANCH_HAS_LOYALTY";

export const SET_RESTAURANT_BRANCH_HAS_MEDIA = "SET_RESTAURANT_BRANCH_HAS_MEDIA";

export const SET_RESTAURANT_HAS_FEEDBACK = "SET_RESTAURANT_HAS_FEEDBACK";

export const SET_RESTAURANT_HAS_MESSAGES = "SET_RESTAURANT_HAS_MESSAGES";

export const GET_RESTAURANT_BRANCH_MODULES_REQUEST = "GET_RESTAURANT_BRANCH_MODULES_REQUEST";
export const GET_RESTAURANT_BRANCH_MODULES_SUCCESS = "GET_RESTAURANT_BRANCH_MODULES_SUCCESS";
export const GET_RESTAURANT_BRANCH_MODULES_ERROR = "GET_RESTAURANT_BRANCH_MODULES_ERROR";

export const CLEAR_ALL_RESTAURANTS_DATA = "CLEAR_ALL_RESTAURANTS_DATA";

export const actions = {
  getAllRestaurants: (key) => async (dispatch) => {
    dispatch({ type: GET_RESTAURANTS_REQUEST });
    await getAllRestaurants(key).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_RESTAURANTS_SUCCESS,
          payload: {
            restaurants: res?.data?.restaurants,
          },
        });
        dispatch({
          type: SET_EXCLUDED_MODULES,
          payload: {
            value: res?.data?.modulesExcluded,
          },
        });
      } else {
        notify.error(res?.data?.message || "Get Restaurants error");
        dispatch({
          type: GET_RESTAURANTS_ERROR,
        });
      }
    });
  },
  getFeedbackRestaurants: () => async (dispatch) => {
    dispatch({ type: GET_FEEDBACK_RESTAURANTS_REQUEST });
    await getAllRestaurants("feedback").then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_FEEDBACK_RESTAURANTS_SUCCESS,
          payload: {
            feedbackRestaurants: res?.data?.restaurants,
          },
        });
      } else {
        notify.error(res?.data?.message || "Get Feedback Restaurants error");
        dispatch({
          type: GET_FEEDBACK_RESTAURANTS_ERROR,
        });
      }
    });
  },
  setSelectedRestaurant: (id, appSource) => (dispatch) => {
    // dispatch(GlobalActions.clearAllData());
    dispatch({
      type: SET_SELECTED_RESTAURANT,
      payload: {
        restaurant: id,
        appSource: appSource,
      },
    });
  },
  setUserSelectedBranch: (branchId) => (dispatch) => {
    dispatch({
      type: SET_USER_SELECTED_BRANCH,
      payload: { branchId: branchId },
    });
  },
  setDeliverySelectedBranch: (branchId) => (dispatch) => {
    dispatch({
      type: SET_DELIVERY_SELECTED_BRANCH,
      payload: { branchId: branchId },
    });
  },
  getNearestDeliveryBranch:
    (restaurantId, lng, lat, deliveryCb, noDeliveryCb, checkOnly = false) =>
      async (dispatch, getState) => {
        const nearestBranchesData = getState().restaurants.nearestBranchesData;
        const appSource = getState().appSource.appSource;
        const key = appSource === "dine-in" ? "tableOrdering" : appSource === "pay" ? "check-menu-and-pay" : appSource === "QR" ? "qrMenu" : "onlineDelivery";

        //check if corresponding data exists, and if its within time range
        const now = moment();
        const dataExists = nearestBranchesData?.find((data) => {
          return (
            data?.coords?.lng === lng &&
            data?.coords?.lat === lat &&
            data?.restaurantId === restaurantId &&
            now.diff(moment(data.additionTime), "hours") < 2
          )
        })
        if (dataExists) {
          if (dataExists?.nearestDeliveryBranch && dataExists?.nearestDeliveryBranch !== "no-delivery") {
            dispatch({
              type: GET_NEAREST_DELIVERY_BRANCH_SUCCESS,
              payload: {
                nearestBranch: dataExists?.nearestDeliveryBranch,
                checkOnly: checkOnly,
              },
            });
            if (deliveryCb) {
              deliveryCb(dataExists?.nearestDeliveryBranch);
            }
          } else {
            dispatch({
              type: GET_NEAREST_DELIVERY_BRANCH_ERROR,
              payload: {
                noDelivery: true,
                checkOnly: checkOnly,
              },
            });
            if (noDeliveryCb) {
              noDeliveryCb();
            }
          }
        } else {
          dispatch({ type: GET_NEAREST_DELIVERY_BRANCH_REQUEST });
          await getNearestDeliveryBranch(restaurantId, lng, lat, key).then((res) => {

            if (res?.status === 200) {
              dispatch({
                type: GET_NEAREST_DELIVERY_BRANCH_SUCCESS,
                payload: {
                  nearestBranch: res?.data?.nearestBranch?.uuid,
                  checkOnly: checkOnly,
                  updateDataArray: true,
                  restaurantId: restaurantId,
                  lng: lng,
                  lat: lat,
                  additionTime: moment().toISOString()
                },
              });
              if (deliveryCb) {
                deliveryCb(res?.data?.nearestBranch?.uuid);
              }
            } else if (res?.status === 609) {
              dispatch({
                type: GET_NEAREST_DELIVERY_BRANCH_ERROR,
                payload: {
                  noDelivery: true,
                  checkOnly: checkOnly,
                  updateDataArray: true,
                  restaurantId: restaurantId,
                  lng: lng,
                  lat: lat,
                  additionTime: moment().toISOString()
                },
              });
              if (noDeliveryCb) {
                noDeliveryCb();
              }
            } else {
              notify.error(res?.data?.message || "Get Nearest Branch error");
              dispatch({
                type: GET_NEAREST_DELIVERY_BRANCH_ERROR,
              });
            }
          });
        }
      },
  resetNearestDeliveryBranch: () => (dispatch) => {
    dispatch({
      type: RESET_NEAREST_DELIVERY_BRANCH,
    });
  },

  //DINE IN ACTIONS
  setDineInSelectedRestaurant: (id) => (dispatch) => {
    dispatch({
      type: SET_DINE_IN_SELECTED_RESTAURANT,
      payload: { restaurant: id },
    });
  },
  setDineInSelectedBranch: (id) => (dispatch) => {
    dispatch({
      type: SET_DINE_IN_SELECTED_BRANCH,
      payload: { branch: id },
    });
  },
  setDineInSelectedTable: (table) => (dispatch) => {
    dispatch({
      type: SET_DINE_IN_SELECTED_TABLE,
      payload: { table: table },
    });
  },

  //PAY ACTIONS
  setPaySelectedRestaurant: (id) => (dispatch) => {
    dispatch({
      type: SET_PAY_SELECTED_RESTAURANT,
      payload: { restaurant: id },
    });
  },
  setPaySelectedBranch: (id) => (dispatch) => {
    dispatch({
      type: SET_PAY_SELECTED_BRANCH,
      payload: { branch: id },
    });
  },
  setPaySelectedTable: (table) => (dispatch) => {
    dispatch({
      type: SET_PAY_SELECTED_TABLE,
      payload: { table: table },
    });
  },

  //QR ACTIONS
  setQRSelectedRestaurant: (id) => (dispatch) => {
    dispatch({
      type: SET_QR_SELECTED_RESTAURANT,
      payload: { restaurant: id },
    });
  },
  setQRSelectedBranch: (id) => (dispatch) => {
    dispatch({
      type: SET_QR_SELECTED_BRANCH,
      payload: { branch: id },
    });
  },

   //GET RESTAURANT MODULES
   getRestaurantModules: (ruuid, appSource) => async (dispatch) => {
    dispatch({
      type: GET_RESTAURANT_MODULES_REQUEST,
      payload: {
        appSource: appSource,
        ruuid: ruuid
      }
    });
    await getRestoBranchModules(ruuid).then((res) => {
      if (res?.status === 200) {
        console.log(res?.data)
        dispatch({
          type: GET_RESTAURANT_MODULES_SUCCESS,
          payload: {
            modules: res?.data?.modules,
            appSource: appSource,
            ruuid: ruuid
          },
        });
      } else {
        notify.error(res?.data?.message || "Get Restaurant/branch modules error");
        dispatch({
          type: GET_RESTAURANT_MODULES_ERROR,
          payload: {
            appSource: appSource,
            ruuid: ruuid
          }
        });
      }
    });
  },

  //GET RESTAURANT BRANCH MODULES
  getRestaurantBranchModules: (ruuid, buuid, appSource) => async (dispatch) => {
    dispatch({
      type: GET_RESTAURANT_BRANCH_MODULES_REQUEST,
      payload: {
        appSource: appSource,
        ruuid: ruuid,
        buuid: buuid
      }
    });
    await getRestoBranchModules(ruuid, buuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_RESTAURANT_BRANCH_MODULES_SUCCESS,
          payload: {
            modules: res?.data?.modules,
            appSource: appSource,
            ruuid: ruuid,
            buuid: buuid,
            hasLoyalty: res?.data?.modules?.find((module) => {
              return module?.key === "loyalty"
            }) ? true : false
          },
        });
      } else {
        notify.error(res?.data?.message || "Get Restaurant/branch modules error");
        dispatch({
          type: GET_RESTAURANT_BRANCH_MODULES_ERROR,
          payload: {
            appSource: appSource,
            ruuid: ruuid,
            buuid: buuid
          }
        });
      }
    });
  },
  setRestaurantBranchHasLoyalty: (appSource, value) => (dispatch) => {
    dispatch({
      type: SET_RESTAURANT_BRANCH_HAS_LOYALTY,
      payload: {
        appSource: appSource,
        value: value
      }
    })
  },
  setRestaurantBranchHasMedia: (appSource, value) => (dispatch) => {
    dispatch({
      type: SET_RESTAURANT_BRANCH_HAS_MEDIA,
      payload: {
        appSource: appSource,
        value: value
      }
    })
  },
  setRestaurantHasFeedback: (appSource, value) => (dispatch) => {
    dispatch({
      type: SET_RESTAURANT_HAS_FEEDBACK,
      payload: {
        appSource: appSource,
        value: value
      }
    })
  },
  setRestaurantHasMessages: (appSource, value) => (dispatch) => {
    dispatch({
      type: SET_RESTAURANT_HAS_MESSAGES,
      payload: {
        appSource: appSource,
        value: value
      }
    })
  },
  clearAllRestaurantsData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_RESTAURANTS_DATA
    })
  }
};
