import { connect } from "react-redux";
import "./CartBarSkeleton.css";
import "../../../Home/CartBar/CartBar.css";


const CartBarSkelton = ({
  restaurantBranchHasMedia
}) => {
    return (
        // <div className='sk-cart-bar white'></div>
        <div
        className={`cart-bar skeleton ${!restaurantBranchHasMedia ? "no-media" : ""}`}
      >
        <div className="cart-bar-skltn">
          <div className="cart-bar-left"></div>
          <div className="cart-bar-middle"></div>
          <div className="cart-bar-right"></div>
        </div>
        <div className="cart-bar-content">
        </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
 restaurantBranchHasMedia: state.appSource.appSource === "QR"
  ? state.restaurants.QRRestaurantBranchHasMedia
  : state.appSource.appSource === "dine-in"
    ? state.restaurants.dineInRestaurantBranchHasMedia
    : state.appSource.appSource === "pay"
      ? state.restaurants.payRestaurantBranchHasMedia
      : state.restaurants.restaurantBranchHasMedia,
});

export default connect(mapStateToProps, null)(CartBarSkelton);

