import {
  GET_RESTAURANTS_REQUEST,
  GET_RESTAURANTS_SUCCESS,
  GET_RESTAURANTS_ERROR,

  GET_FEEDBACK_RESTAURANTS_REQUEST,
  GET_FEEDBACK_RESTAURANTS_SUCCESS,
  GET_FEEDBACK_RESTAURANTS_ERROR,
  
  SET_SELECTED_RESTAURANT,
  SET_USER_SELECTED_BRANCH,
  SET_DELIVERY_SELECTED_BRANCH,
  GET_NEAREST_DELIVERY_BRANCH_REQUEST,
  GET_NEAREST_DELIVERY_BRANCH_SUCCESS,
  GET_NEAREST_DELIVERY_BRANCH_ERROR,
  RESET_NEAREST_DELIVERY_BRANCH,

  //DINE IN ACTIONS
  SET_DINE_IN_SELECTED_RESTAURANT,
  SET_DINE_IN_SELECTED_BRANCH,
  SET_DINE_IN_SELECTED_TABLE,

  //PAY ACTIONS
  SET_PAY_SELECTED_RESTAURANT,
  SET_PAY_SELECTED_BRANCH,
  SET_PAY_SELECTED_TABLE,

  //QR actions
  SET_QR_SELECTED_RESTAURANT,
  SET_QR_SELECTED_BRANCH,

  //GET RESTAURANT MODULES
  GET_RESTAURANT_MODULES_REQUEST,
  GET_RESTAURANT_MODULES_SUCCESS,
  GET_RESTAURANT_MODULES_ERROR,

  SET_RESTAURANT_BRANCH_HAS_LOYALTY,
  SET_RESTAURANT_BRANCH_HAS_MEDIA,
  SET_RESTAURANT_HAS_FEEDBACK,
  SET_RESTAURANT_HAS_MESSAGES,

  //GET RESTAURANT/BRANCH MODULES ACTIONS
  GET_RESTAURANT_BRANCH_MODULES_REQUEST,
  GET_RESTAURANT_BRANCH_MODULES_SUCCESS,
  GET_RESTAURANT_BRANCH_MODULES_ERROR,

  CLEAR_ALL_RESTAURANTS_DATA

} from "../actions/RestaurantsActions";

const initialState = {
  getRestaurantsLoading: false,
  restaurants: null,
  availableRestaurants: null,
  selectedRestaurant: null,
  selectedRestaurantData: null,
  selectedRestaurantAvailableBranches: [],
  selectedRestaurantDeliveryBranches: [],
  selectedRestaurantPickupBranches: [],
  selectedRestaurantDineinBranches: [],
  selectedRestaurantReservationBranches: [],
  selectedBranches: [],
  userSelectedBranch: "",
  deliverySelectedBranch: "",
  nearestBranchesData: [],
  nearestDeliveryBranch: "",
  getNearestDeliveryBranchLoading: false,

  restaurantModules: null,
  restaurantModulesRestaurant: null,
  getRestaurantModulesLoading: false,

  restaurantBranchModules: [],
  restaurantBranchModulesRestaurant: null,
  restaurantBranchModulesBranch: null,
  getRestaurantBranchModulesLoading: false,
  restaurantBranchHasLoyalty: null,
  restaurantBranchHasMedia: null,
  restaurantHasFeedback: null,
  restaurantHasMessages: null,

  getFeedbackRestaurantsLoading: false,
  feedbackRestaurants: [],

  //DINE IN
  dineInSelectedRestaurant: null,
  dineInSelectedRestaurantData: null,
  dineInSelectedRestaurantAvailableBranches: [],
  dineInSelectedBranch: null,
  dineInSelectedTable: null,

  dineInRestaurantModules: null,
  dineInRestaurantModulesRestaurant: null,

  dineInRestaurantBranchModules: [],
  dineInRestaurantBranchHasLoyalty: null,
  dineInRestaurantBranchHasMedia: null,
  dineInRestaurantHasFeedback: null,
  dineInRestaurantHasMessages: null,

  //PAY
  paySelectedRestaurant: null,
  paySelectedRestaurantData: null,
  paySelectedRestaurantAvailableBranches: [],
  paySelectedBranch: null,
  paySelectedTable: null,

  payRestaurantModules: null,
  payRestaurantModulesRestaurant: null,

  payRestaurantBranchModules: [],
  payRestaurantBranchHasLoyalty: null,
  payRestaurantBranchHasMedia: null,
  payRestaurantHasFeedback: null,
  payRestaurantHasMessages: null,

  //QR
  QRSelectedRestaurant: null,
  QRSelectedRestaurantData: null,
  QRSelectedRestaurantAvailableBranches: [],
  QRSelectedBranch: null,

  QRRestaurantModules: null,
  QRRestaurantModulesRestaurant: null,

  QRRestaurantBranchModules: [],
  QRRestaurantBranchHasLoyalty: null,
  QRRestaurantBranchHasMedia: null,
  QRRestaurantHasFeedback: null,
  QRRestaurantHasMessages: null,
};

const updateRestosData = (
  state,
  dataToBeAdded,
  newSelectedResto = null,
  newDineInSelectedResto = null,
  newPaySelectedResto = null,
  newQRSelectedResto = null,
  restaurants = null
) => {
  const availableRestos = restaurants
    ? restaurants.filter((resto) => {
      return (
        resto?.isActive &&
        resto?.branches?.find((branch) => {
          return branch?.isActive;
        })
      );
    })
    : state?.availableRestaurants;

  const selectedResto = newSelectedResto
    ? newSelectedResto
    : state?.selectedRestaurant;
  const selectedRestoData = availableRestos?.find((restaurant) => {
    return restaurant.uuid === selectedResto;
  });

  //dine in
  const dineInSelectedResto = newDineInSelectedResto
    ? newDineInSelectedResto
    : state?.dineInSelectedRestaurant;

  const dineInSelectedRestoData = availableRestos?.find((restaurant) => {
    return restaurant?.uuid === dineInSelectedResto;
  });
  //pay
  const paySelectedResto = newPaySelectedResto
    ? newPaySelectedResto
    : state?.paySelectedRestaurant;

  const paySelectedRestoData = availableRestos?.find((restaurant) => {
    return restaurant?.uuid === paySelectedResto;
  });

  //qr
  const QRSelectedResto = newQRSelectedResto
    ? newQRSelectedResto
    : state?.QRSelectedRestaurant;

  const QRSelectedRestoData = availableRestos?.find((restaurant) => {
    return restaurant?.uuid === QRSelectedResto;
  });

  // if (!selectedRestoData) {
  //   return {
  //     ...state,
  //     ...dataToBeAdded,
  //     selectedRestaurant: "",
  //     userSelectedBranch: "",
  //     selectedRestaurantData: null,
  //     selectedRestaurantAvailableBranches: [],
  //     selectedRestaurantDeliveryBranches: [],
  //     selectedRestaurantPickupBranches: [],
  //     selectedRestaurantDineinBranches: [],
  //     selectedRestaurantReservationBranches: [],
  //   };
  // }

  let selectedRestoValue = "";
  let userSelectedBranchValue = "";
  let selectedRestoAvailableBranches = [];
  let selectedRestoDeliveryBranches = [];
  let selectedRestoPickupBranches = [];
  let selectedRestoDineinBranches = [];
  let selectedRestoReservationBranches = [];

  //dine in
  let dineInSelectedRestoValue = "";
  let dineInSelectedRestoAvailableBranches = [];

  //pay
  let paySelectedRestoValue = "";
  let paySelectedRestoAvailableBranches = [];

  //qr
  let QRSelectedRestoValue = "";
  let QRSelectedRestoAvailableBranches = [];

  if (selectedRestoData) {
    selectedRestoValue = selectedResto;
    userSelectedBranchValue = state?.userSelectedBranch;
    selectedRestoAvailableBranches = selectedRestoData.branches.filter(
      (branch) => {
        return branch.isActive;
      }
    );

    selectedRestoDeliveryBranches = selectedRestoAvailableBranches.filter(
      (branch) => {
        return (
          branch.moduleExceptions === null ||
          !branch.moduleExceptions.includes("delivery")
        );
      }
    );

    selectedRestoPickupBranches = selectedRestoAvailableBranches.filter(
      (branch) => {
        return (
          branch.moduleExceptions === null ||
          !branch.moduleExceptions.includes("pickUp")
        );
      }
    );

    selectedRestoDineinBranches = selectedRestoAvailableBranches.filter(
      (branch) => {
        return (
          branch.moduleExceptions === null ||
          !branch.moduleExceptions.includes("dineIn")
        );
      }
    );

    selectedRestoReservationBranches = selectedRestoAvailableBranches.filter(
      (branch) => {
        return (
          branch.moduleExceptions === null ||
          !branch.moduleExceptions.includes("reservation")
        );
      }
    );
  }

  if (dineInSelectedRestoData) {
    dineInSelectedRestoValue = dineInSelectedResto;
    dineInSelectedRestoAvailableBranches =
      dineInSelectedRestoData?.branches.filter((branch) => {
        return branch.isActive;
      });
  }

  if (paySelectedRestoData) {
    paySelectedRestoValue = paySelectedResto;
    paySelectedRestoAvailableBranches = paySelectedRestoData?.branches.filter(
      (branch) => {
        return branch?.isActive;
      }
    );
  }

  if (QRSelectedRestoData) {
    QRSelectedRestoValue = QRSelectedResto;
    QRSelectedRestoAvailableBranches = QRSelectedRestoData?.branches.filter(
      (branch) => {
        return branch?.isActive;
      }
    );
  }

  return {
    ...state,
    ...dataToBeAdded,
    selectedRestaurant: selectedRestoValue,
    userSelectedBranch: userSelectedBranchValue,
    selectedRestaurantData: selectedRestoData,
    selectedRestaurantAvailableBranches: selectedRestoAvailableBranches,
    selectedRestaurantDeliveryBranches: selectedRestoDeliveryBranches,
    selectedRestaurantPickupBranches: selectedRestoPickupBranches,
    selectedRestaurantDineinBranches: selectedRestoDineinBranches,
    selectedRestaurantReservationBranches: selectedRestoReservationBranches,
    //dine in
    dineInSelectedRestaurant: dineInSelectedRestoValue,
    dineInSelectedRestaurantData: dineInSelectedRestoData,
    dineInSelectedRestaurantAvailableBranches:
      dineInSelectedRestoAvailableBranches,
    //pay
    paySelectedRestaurant: paySelectedRestoValue,
    paySelectedRestaurantData: paySelectedRestoData,
    paySelectedRestaurantAvailableBranches: paySelectedRestoAvailableBranches,
    //QR
    QRSelectedRestaurant: QRSelectedRestoValue,
    QRSelectedRestaurantData: QRSelectedRestoData,
    QRSelectedRestaurantAvailableBranches: QRSelectedRestoAvailableBranches,
  };
};

const updateRestoDataAndAvailableBranches = (
  state,
  newSelectedResto = null,
  restaurants = null,
  selectedRestoState,
  restoDataState,
  availableBranchesState
) => {
  const availableRestos = restaurants
    ? restaurants.filter((resto) => {
      return (
        resto?.isActive &&
        resto?.branches?.find((branch) => {
          return branch?.isActive;
        })
      );
    })
    : state?.availableRestaurants;

  const selectedResto = newSelectedResto
    ? newSelectedResto
    : state[restoDataState];
  const selectedRestoData = availableRestos.find((restaurant) => {
    return restaurant.uuid === selectedResto;
  });

  if (!selectedRestoData) {
    return {
      ...state,
      [selectedRestoState]: "",
      [restoDataState]: "",
      [availableBranchesState]: [],
    };
  }

  const selectedRestoAvailableBranches = selectedRestoData.branches.filter(
    (branch) => {
      return branch.isActive;
    }
  );
  return {
    ...state,
    [selectedRestoState]: selectedResto,
    [restoDataState]: selectedRestoData,
    [availableBranchesState]: selectedRestoAvailableBranches,
  };
};

const getUpdatedNearestBranchesDataArray = (currentArray, restaurantId, lat, lng, nearestDeliveryBranch, additionTime) => {
  const filteredArray = currentArray?.filter((row) => {
    return !(row?.restaurantId === restaurantId && row?.coords?.lat === lat && row?.coords?.lng === lng)
  })

  const updatedArray = [
    ...filteredArray,
    {
      coords: {
        lat: lat,
        lng: lng
      },
      restaurantId: restaurantId,
      nearestDeliveryBranch: nearestDeliveryBranch,
      additionTime: additionTime
    }
  ]

  return updatedArray
}

function restaurants(state = initialState, action) {
  switch (action.type) {
    case GET_RESTAURANTS_REQUEST:
      return {
        ...state,
        getRestaurantsLoading: true,
      };
    case GET_RESTAURANTS_SUCCESS:
      return updateRestosData(
        state,
        {
          getRestaurantsLoading: false,
          restaurants: action?.payload?.restaurants,
          availableRestaurants: action.payload.restaurants.filter((resto) => {
            return (
              resto?.isActive &&
              resto?.branches?.find((branch) => {
                return branch?.isActive;
              })
            );
          }),
        },
        null,
        null,
        null,
        null,
        action?.payload?.restaurants
      );

    case GET_RESTAURANTS_ERROR:
      return {
        ...state,
        getRestaurantsLoading: false,
      };

    case SET_SELECTED_RESTAURANT:
      return updateRestosData(state, {}, action?.payload?.restaurant, null);

    case SET_USER_SELECTED_BRANCH:
      return {
        ...state,
        userSelectedBranch: action.payload?.branchId || "",
      };
    case SET_DELIVERY_SELECTED_BRANCH:
      return {
        ...state,
        deliverySelectedBranch: action.payload?.branchId || "",
      };
    case GET_NEAREST_DELIVERY_BRANCH_REQUEST:
      return {
        ...state,
        getNearestDeliveryBranchLoading: true,
      };
    case GET_NEAREST_DELIVERY_BRANCH_SUCCESS:
      if (action?.payload?.checkOnly === true) {
        return {
          ...state,
          getNearestDeliveryBranchLoading: false,
          nearestDeliveryBranch: action.payload?.nearestBranch || "",
          nearestBranchesData: action?.payload?.updateDataArray ? getUpdatedNearestBranchesDataArray(state.nearestBranchesData, action?.payload?.restaurantId, action?.payload?.lat, action?.payload?.lng, action?.payload?.nearestBranch, action?.payload?.additionTime) : [...state.nearestBranchesData]
        };
      } else {
        return {
          ...state,
          getNearestDeliveryBranchLoading: false,
          nearestDeliveryBranch: action.payload?.nearestBranch || "",
          nearestBranchesData: action?.payload?.updateDataArray ? getUpdatedNearestBranchesDataArray(state.nearestBranchesData, action?.payload?.restaurantId, action?.payload?.lat, action?.payload?.lng, action?.payload?.nearestBranch, action?.payload?.additionTime) : [...state.nearestBranchesData]
          //   deliverySelectedBranch: action.payload?.nearestBranch || "",
        };
      }
    case GET_NEAREST_DELIVERY_BRANCH_ERROR:
      // if (action.payload?.noDelivery === true || action?.payload?.checkOnly === false) {
      if (action.payload?.noDelivery === true) {
        return {
          ...state,
          getNearestDeliveryBranchLoading: false,
          nearestDeliveryBranch: "no-delivery",
          nearestBranchesData: action?.payload?.updateDataArray ? getUpdatedNearestBranchesDataArray(state.nearestBranchesData, action?.payload?.restaurantId, action?.payload?.lat, action?.payload?.lng, "no-delivery", action?.payload?.additionTime) : [...state.nearestBranchesData]
          // deliverySelectedBranch: "",
        };
      } else {
        return {
          ...state,
          getNearestDeliveryBranchLoading: false,
        };
      }
    case RESET_NEAREST_DELIVERY_BRANCH:
      return {
        ...state,
        nearestDeliveryBranch: "",
        getNearestDeliveryBranchLoading: false,
      };
    // DINE IN
    case SET_DINE_IN_SELECTED_RESTAURANT:
      return updateRestosData(
        state,
        {},
        null,
        action?.payload?.restaurant,
        null,
        null,
        null
      );
    // const selectedRestoData = state.availableRestaurants?.find((resto) => {
    //   return resto?.uuid === action?.payload?.restaurant;
    // });
    // return {
    //   ...state,
    //   dineInSelectedRestaurant: action?.payload?.restaurant,
    //   dineInSelectedRestaurantData: selectedRestoData,
    //   dineInSelectedRestaurantAvailableBranches:
    //     selectedRestoData?.branches?.find((branch) => {
    //       return branch?.isActive;
    //     }),
    // };
    case SET_DINE_IN_SELECTED_BRANCH:
      return {
        ...state,
        dineInSelectedBranch: action.payload.branch,
      };
    case SET_DINE_IN_SELECTED_TABLE:
      return {
        ...state,
        dineInSelectedTable: action.payload.table,
      };
    // PAY
    case SET_PAY_SELECTED_RESTAURANT:
      return updateRestosData(
        state,
        {},
        null,
        null,
        action?.payload?.restaurant,
        null,
        null
      );
    // return {
    //   ...state,
    //   paySelectedRestaurant: action?.payload?.restaurant,
    //   paySelectedRestaurantData: state.availableRestaurants?.find((resto) => {
    //     return resto?.uuid === action?.payload?.restaurant;
    //   }),
    // };
    case SET_PAY_SELECTED_BRANCH:
      return {
        ...state,
        paySelectedBranch: action.payload.branch,
      };
    case SET_PAY_SELECTED_TABLE:
      return {
        ...state,
        paySelectedTable: action.payload.table,
      };
    //QR
    case SET_QR_SELECTED_RESTAURANT:
      return updateRestosData(
        state,
        {},
        null,
        null,
        null,
        action?.payload?.restaurant,
        null
      );
    // return {
    //   ...state,
    //   QRSelectedRestaurant: action?.payload?.restaurant,
    //   QRSelectedRestaurantData: state.availableRestaurants?.find((resto) => {
    //     return resto?.uuid === action?.payload?.restaurant;
    //   }),
    // };
    case SET_QR_SELECTED_BRANCH:
      return {
        ...state,
        QRSelectedBranch: action.payload.branch,
      };
    case GET_RESTAURANT_BRANCH_MODULES_REQUEST:
      return {
        ...state,
        getRestaurantBranchModulesLoading:
          state[action?.payload?.appSource === "dine-in" ?
            "dineInRestaurantBranchModules"
            : action?.payload?.appSource === "pay" ?
              "payRestaurantBranchModules"
              : action?.payload?.appSource === "QR"
                ? "QRRestaurantBranchModules"
                : "restaurantBranchModules"
          ] && state[action?.payload?.appSource === "dine-in" ?
            "dineInRestaurantBranchModulesRestaurant"
            : action?.payload?.appSource === "pay" ?
              "payRestaurantBranchModulesRestaurant"
              : action?.payload?.appSource === "QR"
                ? "QRRestaurantBranchModulesRestaurant"
                : "restaurantBranchModulesRestaurant"
          ] === action?.payload?.ruuid
            && state[action?.payload?.appSource === "dine-in" ?
              "dineInRestaurantBranchModulesBranch"
              : action?.payload?.appSource === "pay" ?
                "payRestaurantBranchModulesBranch"
                : action?.payload?.appSource === "QR"
                  ? "QRRestaurantBranchModulesBranch"
                  : "restaurantBranchModulesBranch"
            ] === action?.payload?.buuid ? false : true
      };
    case GET_RESTAURANT_BRANCH_MODULES_SUCCESS:
      return {
        ...state,
        [action?.payload?.appSource === "dine-in" ?
          "dineInRestaurantBranchModules"
          : action?.payload?.appSource === "pay" ?
            "payRestaurantBranchModules"
            : action?.payload?.appSource === "QR"
              ? "QRRestaurantBranchModules"
              : "restaurantBranchModules"
        ]: action.payload.modules,
        [action?.payload?.appSource === "dine-in" ?
          "dineInRestaurantBranchModulesRestaurant"
          : action?.payload?.appSource === "pay" ?
            "payRestaurantBranchModulesRestaurant"
            : action?.payload?.appSource === "QR"
              ? "QRRestaurantBranchModulesRestaurant"
              : "restaurantBranchModulesRestaurant"
        ]: action?.payload?.ruuid,
        ["restaurantBranchModulesBranch"]: action?.payload?.appSource === "APP" ? action?.payload?.buuid : state["restaurantBranchModulesBranch"],
        getRestaurantBranchModulesLoading: false,
        [action?.payload?.appSource === "dine-in" ?
          "dineInRestaurantBranchHasLoyalty"
          : action?.payload?.appSource === "pay" ?
            "payRestaurantBranchHasLoyalty"
            : action?.payload?.appSource === "QR"
              ? "QRRestaurantBranchHasLoyalty"
              : "restaurantBranchHasLoyalty"
        ]: action.payload.hasLoyalty,
      };
    case GET_RESTAURANT_BRANCH_MODULES_ERROR:
      return {
        ...state,
        getRestaurantBranchModulesLoading: false
      }
   case GET_RESTAURANT_MODULES_REQUEST:
      return {
        ...state,
        getRestaurantModulesLoading:
          state[action?.payload?.appSource === "dine-in" ?
            "dineInRestaurantModules"
            : action?.payload?.appSource === "pay" ?
              "payRestaurantModules"
              : action?.payload?.appSource === "QR"
                ? "QRRestaurantModules"
                : "restaurantModules"
          ] && state[action?.payload?.appSource === "dine-in" ?
            "dineInRestaurantModulesRestaurant"
            : action?.payload?.appSource === "pay" ?
              "payRestaurantModulesRestaurant"
              : action?.payload?.appSource === "QR"
                ? "QRRestaurantModulesRestaurant"
                : "restaurantModulesRestaurant"
          ] === action?.payload?.ruuid
           ? false : true
      };
    case GET_RESTAURANT_MODULES_SUCCESS:
      return {
        ...state,
        [action?.payload?.appSource === "dine-in" ?
          "dineInRestaurantModules"
          : action?.payload?.appSource === "pay" ?
            "payRestaurantModules"
            : action?.payload?.appSource === "QR"
              ? "QRRestaurantModules"
              : "restaurantModules"
        ]: action.payload.modules,
        [action?.payload?.appSource === "dine-in" ?
          "dineInRestaurantModulesRestaurant"
          : action?.payload?.appSource === "pay" ?
            "payRestaurantModulesRestaurant"
            : action?.payload?.appSource === "QR"
              ? "QRRestaurantModulesRestaurant"
              : "restaurantModulesRestaurant"
        ]: action?.payload?.ruuid,
        getRestaurantModulesLoading: false,
      };
    case GET_RESTAURANT_MODULES_ERROR:
      return {
        ...state,
        getRestaurantModulesLoading: false
      }
    case SET_RESTAURANT_BRANCH_HAS_LOYALTY:
      return {
        ...state,
        [action?.payload?.appSource === "dine-in" ?
          "dineInRestaurantBranchHasLoyalty"
          : action?.payload?.appSource === "pay" ?
            "payRestaurantBranchHasLoyalty"
            : action?.payload?.appSource === "QR"
              ? "QRRestaurantBranchHasLoyalty"
              : "restaurantBranchHasLoyalty"
        ]: action.payload.value,
      }
      case SET_RESTAURANT_BRANCH_HAS_MEDIA:
        return {
          ...state,
          [action?.payload?.appSource === "dine-in" ?
            "dineInRestaurantBranchHasMedia"
            : action?.payload?.appSource === "pay" ?
              "payRestaurantBranchHasMedia"
              : action?.payload?.appSource === "QR"
                ? "QRRestaurantBranchHasMedia"
                : "restaurantBranchHasMedia"
          ]: action.payload.value,
        }
    case SET_RESTAURANT_HAS_FEEDBACK:
      return {
        ...state,
        [action?.payload?.appSource === "dine-in" ?
          "dineInRestaurantHasFeedback"
          : action?.payload?.appSource === "pay" ?
            "payRestaurantHasFeedback"
            : action?.payload?.appSource === "QR"
              ? "QRRestaurantHasFeedback"
              : "restaurantHasFeedback"
        ]: action.payload.value,
      }
    case SET_RESTAURANT_HAS_MESSAGES:
      return {
        ...state,
        [action?.payload?.appSource === "dine-in" ?
          "dineInRestaurantHasMessages"
          : action?.payload?.appSource === "pay" ?
            "payRestaurantHasMessages"
            : action?.payload?.appSource === "QR"
              ? "QRRestaurantHasMessages"
              : "restaurantHasMessages"
        ]: action.payload.value,
      }
    case CLEAR_ALL_RESTAURANTS_DATA:
      return {
        ...state,
        selectedRestaurantData: null,
        selectedRestaurantAvailableBranches: [],
        selectedRestaurantDeliveryBranches: [],
        selectedRestaurantPickupBranches: [],
        selectedRestaurantDineinBranches: [],
        selectedRestaurantReservationBranches: [],
        selectedBranches: [],
        // userSelectedBranch: "",
        // deliverySelectedBranch: "",
        nearestBranchesData: [],
        // nearestDeliveryBranch: "",
        getNearestDeliveryBranchLoading: false,

        restaurantModules: null,
        restaurantModulesRestaurant: null,
        getRestaurantModulesLoading: false,

        dineInRestaurantModules: null,
        dineInRestaurantModulesRestaurant: null,
        dineInGetRestaurantModulesLoading: false,

        payRestaurantModules: null,
        payRestaurantModulesRestaurant: null,
        payGetRestaurantModulesLoading: false,

        QRRestaurantModules: null,
        QRRestaurantModulesRestaurant: null,
        QRGetRestaurantModulesLoading: false,

        restaurantBranchModules: [],
        restaurantBranchModulesRestaurant: null,
        restaurantBranchModulesBranch: null,
        getRestaurantBranchModulesLoading: false,

        dineInRestaurantBranchModules: [],
        dineInRestaurantBranchModulesRestaurant: null,
        dineInRestaurantBranchModulesBranch: null,
        dineInGetRestaurantBranchModulesLoading: false,

        payRestaurantBranchModules: [],
        payRestaurantBranchModulesRestaurant: null,
        payRestaurantBranchModulesBranch: null,
        payGetRestaurantBranchModulesLoading: false,

        QRRestaurantBranchModules: [],
        QRRestaurantBranchModulesRestaurant: null,
        QRRestaurantBranchModulesBranch: null,
        QRGetRestaurantBranchModulesLoading: false,

        // restaurantBranchHasLoyalty: null,
        restaurants: null,
        availableRestaurants: null
      }
      case GET_FEEDBACK_RESTAURANTS_REQUEST:
        return {
          ...state,
          getFeedbackRestaurantsLoading: true,
        };
      case GET_FEEDBACK_RESTAURANTS_SUCCESS:
        return {
          ...state,
          feedbackRestaurants: action?.payload?.feedbackRestaurants,
          getFeedbackRestaurantsLoading: false
        }
      case GET_FEEDBACK_RESTAURANTS_ERROR:
        return {
          ...state,
          getFeedbackRestaurantsLoading: false,
        };
    default:
      return state;
  }
}

export default restaurants;
