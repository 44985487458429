import React, { useEffect, Suspense, useState } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { actions as RestaurantsActions } from "../redux/actions/RestaurantsActions";
import { actions as AppSourceActions } from "../redux/actions/AppSourceActions";
import { actions as CartActions } from "../redux/actions/CartActions";
import { actions as ProductsActions } from "../redux/actions/ProductsActions";
import { actions as DeliveryMethodActions } from "../redux/actions/DeliveryMethodActions";
import UserRoute from "./UserRoute";
import Loader from "../components/Loader/Loader";

const QRRoute = ({
  element: Element,
  outlet,
  isUserRoute,
  elementFallback,
  appSource,
  QRSelectedRestaurant,
  QRSelectedBranch,
  availableRestaurants,
  restaurants,
  getRestaurantsLoading,
  clearCart,
  setQRSelectedRestaurant,
  setQRSelectedBranch,
  setSelectedMenu,
  resetMenus,
  setAppSource,
  changeDeliveryMethod,
  from,
  ...rest
}) => {

  const location = useLocation();
  const navigate = useNavigate();

  const fallback = <Loader />;

  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    // Use the reloadKey to force a re-render when needed
    setReloadKey((prevKey) => prevKey + 1);
  }, [location.pathname]);

  useEffect(() => {
    //
    if (!getRestaurantsLoading && reloadKey === 1) {
      if (restaurants) {
        if (restaurants?.length === 0) {
          // window.location.replace("https://www.cloudix.ai");

          return;
        }
        // Extract the UUID from the URL pathname
        const pathSegments = location.pathname.split("/");
        const restoUuid = pathSegments[2];
        const branchUuid = pathSegments[3];

        // Check if the selected restaurant is valid
        const restaurantExists = restaurants?.find((resto) => {
          return resto?.uuid === restoUuid;
        })

        let branchExists;

        if (restaurantExists) {
          branchExists = restaurantExists?.branches?.find((branch) => {
            return (
              branch?.uuid === branchUuid && branch?.isActive
            )
          })
        }

        if (!restaurantExists || !branchExists) {
          //show page 404 not found
          // window.location.replace("https://www.cloudix.ai");
          // navigate("/error");
          return;
        }
        setAppSource("QR");
        changeDeliveryMethod("dineIn");


        if (!QRSelectedRestaurant || (QRSelectedRestaurant && QRSelectedRestaurant !== restoUuid) || !QRSelectedBranch || (QRSelectedBranch && QRSelectedBranch !== branchUuid)) {
          clearCart(appSource);
          setQRSelectedRestaurant(restoUuid);
          setQRSelectedBranch(branchUuid);
          resetMenus(appSource);
          setSelectedMenu(null, appSource);
          return;
        }
      }
    }
  }, [location.pathname,
    QRSelectedRestaurant,
    QRSelectedBranch,
    availableRestaurants,
    restaurants,
    getRestaurantsLoading,
    reloadKey
  ]);

  if (isUserRoute) {
    return (
      <UserRoute element={Element} />
    )
  } else {
    return (
      <Suspense fallback={getRestaurantsLoading ? <Loader /> : elementFallback ? elementFallback : fallback}>
        <Element {...rest} />
        {outlet && <Outlet />}
      </Suspense>
    )
  }
};


const mapStateToProps = (state) => ({
  appSource: state.appSource.appSource,
  QRSelectedRestaurant: state.restaurants.QRSelectedRestaurant,
  QRSelectedBranch: state.restaurants.QRSelectedBranch,
  availableRestaurants: state.restaurants.availableRestaurants,
  restaurants: state.restaurants.restaurants,
  getRestaurantsLoading: state.restaurants.getRestaurantsLoading
});


const mapDispatchToProps = (dispatch) => ({
  setQRSelectedRestaurant: (value) => {
    dispatch(RestaurantsActions.setQRSelectedRestaurant(value));
  },
  setQRSelectedBranch: (value) => {
    dispatch(RestaurantsActions.setQRSelectedBranch(value));
  },
  clearCart: (appSource) => {
    dispatch(CartActions.clearCart(appSource));
  },
  setAppSource: (value) => {
    dispatch(AppSourceActions.setAppSource(value));
  },
  setSelectedMenu: (menuId, appSource) =>
    dispatch(ProductsActions.setSelectedMenu(menuId, appSource)),
  resetMenus: (appSource) =>
    dispatch(ProductsActions.resetMenus(appSource)),
  changeDeliveryMethod: (method) =>
    dispatch(DeliveryMethodActions.changeDeliveryMethod(method))
});


export default connect(mapStateToProps, mapDispatchToProps)(QRRoute);