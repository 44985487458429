import { Suspense, useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader/Loader";

const fallback = <Loader/>
const UserRoute = ({ 
  element: Element, 
  token, 
  appSource,
  selectedRestaurant,
  dineInSelectedBranch,
  dineInSelectedTable,
  paySelectedBranch,
  paySelectedTable,
  QRSelectedBranch,
  ...rest
}) => {
  const location = useLocation();

  const [loginUrl, setLoginUrl] = useState("");

  useEffect(() => {
    if (appSource) {
      let loginUrlValue;

      if (appSource === "APP") {
        loginUrlValue = "/login"
      } else if (appSource === "dine-in") {
        loginUrlValue = `/dine-in/${selectedRestaurant}/${dineInSelectedBranch}/${dineInSelectedTable}/login`
      } else if (appSource === "pay") {
        loginUrlValue = `/pay/${selectedRestaurant}/${paySelectedBranch}/${paySelectedTable}/login`
      } else if (appSource === "QR") {
        loginUrlValue = `/qr/${selectedRestaurant}/${QRSelectedBranch}/login`
      }
      setLoginUrl(loginUrlValue);
    }
  }, [appSource])
  
  return token ? (
    <Suspense fallback={fallback}>
      <Element {...rest} />
    </Suspense>
  ) : (
    <Navigate to={loginUrl} state={{ from: location.pathname }} />
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  appSource: state.appSource.appSource,
  selectedRestaurant: state.appSource.appSource === "dine-in"
  ? state.restaurants.dineInSelectedRestaurant
  : state.appSource.appSource === "pay"
    ? state.restaurants.paySelectedRestaurant
    : state.appSource.appSource === "QR"
      ? state.restaurants.QRSelectedRestaurant
      : state.restaurants.selectedRestaurant,
  dineInSelectedBranch: state.restaurants.dineInSelectedBranch,
  dineInSelectedTable: state.restaurants.dineInSelectedTable,
  paySelectedBranch: state.restaurants.paySelectedBranch,
  paySelectedTable: state.restaurants.paySelectedTable,
  QRSelectedBranch: state.restaurants.QRSelectedBranch
});
export default connect(mapStateToProps)(UserRoute);
